<template>
  <div class="user">
    <!-- 搜索区域 -->
    <div class="header">
      <el-input
        class="input"
        v-model="input"
        placeholder="输入名称或手机号"
      ></el-input>
      <el-select
        class="input"
        v-model="select_chain_id"
        placeholder="请选择学校"
      >
        <el-option
          v-for="item in chainData"
          :key="item.shop_user_ai_chain_id"
          :label="item.chain_name"
          :value="item.shop_user_ai_chain_id"
        ></el-option>
      </el-select>
      <div class="button-two">
        <el-button type="primary" @click="searchInp">查询</el-button>
        <div class="upload">
          <el-button slot="trigger" type="primary" @click.native="upLoadHandle">批量添加</el-button>
          <el-button type="primary" class="m-r" @click="userVisible = true">
            新增用户
          </el-button>
        </div>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="wrapper">
      <el-table :data="tableData" v-loading="loading" style="width: 100%">
        <el-table-column
          prop="name"
          label="用户名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号码"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="is_enable"
          label="是否启用"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="created"
          label="添加日期"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="chain_name"
          label="所属学校"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" width="320">
          <template slot-scope="scope">
            <el-button size="mini" @click="code(scope.row)">查看</el-button>
            <el-button size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button size="mini" @click="handleAlter(scope.row)"
              >{{ scope.row.is_enable === "已启用" ? "禁" : "启" }}用</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增编辑 -->
    <el-dialog
      :title="shop_user_id ? '编辑用户' : '新增用户'"
      :visible.sync="userVisible"
      :before-close="handleClose"
    >
      <el-form
        :model="userForm"
        :rules="userRules"
        ref="userForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="userForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="昵称" prop="user_name">
              <el-input v-model="userForm.user_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄" prop="age">
              <el-input v-model="userForm.age"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="工作年龄" prop="job_age">
              <el-input v-model="userForm.job_age"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="!shop_user_id">
            <el-form-item label="密码" prop="password">
              <el-input v-model="userForm.password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="gender">
              <el-input v-model="userForm.gender"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学号" prop="number">
              <el-input v-model="userForm.number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班级" prop="college">
              <el-input v-model="userForm.college"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="userForm.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学历" prop="education">
              <el-input v-model="userForm.education"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入学时间" prop="enrollment">
              <el-input v-model="userForm.enrollment"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="submitForm('userForm')"
            >保存</el-button
          >
          <el-button @click="resetForm('userForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 查看弹窗 -->
    <el-dialog
      title="用户信息"
      :visible.sync="dialogCode"
      :before-close="close"
    >
      <el-table :data="userMessageData">
        <el-table-column property="name" label="姓名"></el-table-column>
        <el-table-column property="gender" label="性别"></el-table-column>
        <el-table-column
          property="cell_phone"
          label="手机号码"
        ></el-table-column>
        <el-table-column
          property="enrollment"
          label="入学时间"
        ></el-table-column>
        <el-table-column property="education" label="学历"></el-table-column>
        <el-table-column property="number" label="学号"></el-table-column>
        <el-table-column property="college" label="班级"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 上传文件弹窗 -->
    <el-dialog
      title="批量导入"
      :visible.sync="upLoadVisible"
      width="30%"
      :before-close="upLoadHandleClose"
    >
      <div class="downLoad">
        <a
          href="https://bmbpdf.oss-cn-hangzhou.aliyuncs.com/%E5%AD%A6%E7%94%9F%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
          downLoad="模板"
          ><i class="el-icon-download"></i>导出模板</a
        >
      </div>
      <!-- <Upload :request="onAiUploadMessage" /> -->
       <el-upload action="#" accept=".xls,.xlsx" :show-file-list="false" :http-request="httpRequest">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          <em>点击上传文件</em>(请先下载模板)
        </div>
       </el-upload>
    </el-dialog>
    <!-- 分页 -->
    <MyPagination
      :total="total"
      :pageSize="query_info.limit"
      @changePage="changePage"
      :currentPage="query_info.page"
    />
  </div>
</template>

<script>
import {
  aiChainlist,
  aiUserlist,
  shopStatus,
  delUser,
  aiUserMessage,
  aiUploadMessage,
  updateStudent,
  getAdminUserMessage
} from "@/request/api";
import MyPagination from "@/components/MyPagination.vue";
const XLSX = require("xlsx");
// import Upload from "@/components/Upload.vue";

export default {
  inject: ["reload"],
  components: {
    MyPagination,
    // Upload,
  },
  data() {
    return {
      userVisible: false, //控制新增编辑弹窗
      upLoadVisible: false, //控制上传文件弹窗
      chainData: [],
      select_chain_id: "",
      input: "",
      tableData: [],
      loading: false,
      query_info: {
        limit: 10,
        page: 1,
      },
      total: 0,
      user_message: [],
      dialogCode: false,
      userMessageData: [],
      userForm: {},
      userRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        user_name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        job_age: [
          { required: true, message: "请输入工作年龄", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        gender: [{ required: true, message: "请输入性别", trigger: "blur" }],
        number: [{ required: true, message: "请输入学号", trigger: "blur" }],
        college: [{ required: true, message: "请输入班级", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        enrollment: [
          { required: true, message: "请选择入学时间", trigger: "blur" },
        ],
        education: [{ required: true, message: "请输入学历", trigger: "blur" }],
      },
      shop_user_id: null,
    };
  },
  methods: {
    handleClose() {
      this.shop_user_id = null;
      this.userVisible = false;
      this.userForm = {};
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const body = {
            ...this.userForm,
            isNew: true,
            ai_jurisdiction: 4
          };
          if (this.shop_user_id) {
            body.shop_user_id = this.shop_user_id;
          }
          const res = await updateStudent(body);
          if (res.data.code === "1000") {
            this.$message({
              type: "success",
              message: "新增成功",
            });
            this.searchInp();
            if (this.shop_user_id) {
              this.userVisible = false;
            }
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 批量添加用户
    httpRequest(options) {
      // 获取文件
      const file = options.file
      const reader = new FileReader()

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })

        // 只处理第一个工作表
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];

        let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        if (JSON.stringify(jsonData[1]) !== '["姓名","性别","班级","学号","手机号","入学时间","学历","密码","账号名","年龄","工作年龄","专业"]') {
          return this.$message.error('导入表格字段不匹配');
        }

        let reqData = [];
        for (let i = 2; i < jsonData.length; i++) {
          let indexCount = 0;
          reqData[i - 2] = jsonData[i].map(value => {
            indexCount++
            return value
          })

          if (indexCount < jsonData[i].length) {
            jsonData = false
          }
        }
        if (jsonData === false) {
          return this.$message.error('请完善表格信息')
        }

        this.takeItems(reqData).forEach(ele => {
          this.uploadMessage({ file: JSON.stringify(ele) }).then(() => {
            this.searchInp();
          })
        })
        this.$message({ type: "success", message: "上传成功！" });
      }

      // 读取文件内容，触发onload事件。
      reader.readAsArrayBuffer(file)
    },
    takeItems(arr) {
      let result = []
      let chunkSize = 20

      for (let i = 0; i < arr.length; i += chunkSize) {
        // 计算本次需要取出的元素的结束索引
        let end = Math.min(i + chunkSize, arr.length)
        // 截取子数组
        result.push(arr.slice(i, end));

        // 如果已经取到最后一个子数组并且其长度小于chunkSize，则退出循环
        // 这样可以避免在最后一个子数组不足3个元素时再次循环
        if (end === arr.length && end - i < chunkSize) {
          break
        }
      }

      return result
    },
    // 批量上传接口
    async uploadMessage(reqData) {
      try {
        const res = await aiUploadMessage(reqData)
        if (res.status !== 200 || res.data.code !== "1000") return this.$message.error(res.data.msg)
      } catch(error) {
        this.$message.error('请求发生错误：' + error.message)
      }
    },
    // onAiUploadMessage(formData) {
    //   const file = formData

    //   aiUploadMessage(file).then((res) => {
    //     if (res.status === 200) {
    //       this.searchInp();
    //       this.$message({
    //         type: "success",
    //         message: "上传成功",
    //       });
    //     } else {
    //       this.$message({
    //         type: "error",
    //         message: "上传失败",
    //       });
    //     }
    //   }).catch(() => {
    //     this.onAiUploadMessage(file)
    //   });
    // },
    upLoadHandle() {
      this.upLoadVisible = true;
    },
    upLoadHandleClose() {
      this.upLoadVisible = false;
    },
    // 获取用户列表
    async getUserList() {
      if (this.loading) return;

      this.loading = true;
      try {
        const res = await aiUserlist(
          this.query_info.limit,
          this.query_info.page,
          this.input,
          this.select_chain_id,
          4
        );
        this.loading = false;
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }
        res.data.data.userList.forEach((element, index, arr) => {
          element.is_enable === 0
            ? (arr[index].is_enable = "已启用")
            : (arr[index].is_enable = "已禁用");
        });

        this.tableData = res.data.data.userList;
        this.total = res.data.data.totalNumber;
      } catch (error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    // 获取学校列表
    async getChain() {
      try {
        const res = await aiChainlist(9999, 1, "");
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.chainData = res.data.data.chainList;
      } catch (error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    // 搜索按钮
    searchInp() {
      this.query_info.page = 1;
      this.tableData = [];
      this.getUserList();
    },
    // 查看
    async code(row) {
      try {
        const res = await aiUserMessage(row.shop_user_id);
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.userMessageData.push(res.data.data);
        this.dialogCode = true;
      } catch (error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    close() {
      this.userMessageData = [];
      this.dialogCode = false;
    },
    // 编辑
    async handleEdit(row) {
      const res = await getAdminUserMessage(row.shop_user_id);
      if (res.data.code === "1000") {
        this.userForm = { ...res.data.data };
        this.userVisible = true;
        this.shop_user_id = row.shop_user_id;
      }
    },
    // 启用或禁用
    async handleAlter(row) {
      try {
        let postData = {
          shop_user_id: row.shop_user_id,
          is_enable: row.is_enable === "已启用" ? 1 : 0,
        };
        const res = await shopStatus(postData);
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.getUserList();
        this.$message({ type: "success", message: "修改成功！" });
        this.reload();
      } catch (error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    // 删除
    handleDelete(row) {
      this.$confirm("是否删除该用户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await delUser({ shop_user_id: row.shop_user_id });
          if (res.status !== 200 || res.data.code !== "1000") {
            return this.$message.error(res.data.msg);
          }

          this.$message({ type: "success", message: "删除成功！" });
          this.reload();
        })
        .catch(() => {
          this.$message({ type: "info", message: "已取消删除" });
        });
    },
    // 取分页页码
    changePage(page) {
      this.query_info.page = page;
      this.tableData = [];
      this.getUserList();
    },
  },
  mounted() {
    this.getChain();
    this.getUserList();
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-dragger {
  width: 100%;
  height: 140px;
}
/deep/.el-upload {
  width: 100%;
  height: 140px;
}
.user {
  padding: 20px;
  .downLoad {
    text-align: center;
    margin-bottom: 20px;
    color: #579eff;
    cursor: pointer;
  }
  .header {
    display: flex;
    margin-bottom: 20px;
    .input {
      width: 240px;
      margin-right: 10px;
    }
    .button-two {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .upload {
        display: flex;
        .m-r {
          margin-left: 10px;
          .goto {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
